import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IProductWarranty } from 'src/app/core/models/api/products';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class CreateBookingModeViewModel {
  private _form: FormGroup;
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];
  private _warranties: Array<IProductWarranty> = [];

  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_DESCRIPTION = 'description';

  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;

  constructor() {
    this._form = new FormGroup({
      [this.C_INSURANCE_COMPANY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl(null, [Validators.required, forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_DESCRIPTION]: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z]{1,5}(\+[a-zA-Z]{1,5})*$/)
      ])
    });

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );
  }

  warrantyColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'abbrv',
      fieldHeader: $localize`:@@app.booking.mode.create.product.warranties.warranty.column:Garantía`
    },
    {
      fieldName: 'description',
      fieldHeader: $localize`:@@app.booking.mode.create.product.warranties.description.column:Descripción`
    }
  ];

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  get warranties(): Array<IProductWarranty> {
    return this._warranties;
  }

  set warranties(value: Array<IProductWarranty>) {
    this._warranties = value;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  get insuranceCompanyId(): string {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get description(): string {
    return this.getControl(this.C_DESCRIPTION).value;
  }

  set description(value: string) {
    this.getControl(this.C_DESCRIPTION).setValue(value);
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this.insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this.industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this.products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this.products;
  }
}
