import { CurrencyPipe, DecimalPipe, PercentPipe, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  HowdenAlertsModule,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenUserService,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
  getPaginatorIntl
} from '@howdeniberia/core-front';
import 'moment/locale/es';
import { NgxEchartsModule } from 'ngx-echarts';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage } from 'ngx-webstorage';
import { ApiConfigurations } from 'src/environments/api-configurations';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHeaderModule } from './features/app-header/app-header.module';
import { CertificatesModule } from './features/certificates/certtificates.module';
import { ClaimsModule } from './features/claims/claims.module';
import { DashboardsModule } from './features/dashboards/dashboards.module';
import { EndorsementsModule } from './features/endorsements/endorsements.module';
import { FleetsModule } from './features/fleets/fleets.module';
import { NotificationsModule } from './features/notifications/notifications.module';
import { PoliciesModule } from './features/policies/policies.module';
import { ProcessesModule } from './features/processes/processes.module';
import { ReportsModule } from './features/reports/reports.module';
import { UnitsModule } from './features/units/units.module';

registerLocaleData(localeEs, 'es');

function getLocaleId(userSrv: HowdenUserService) {
  return userSrv?.userData?.selectedLanguage ?? 'es';
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    ToastrModule.forRoot(),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({ format: 'html', sanitize: true }),
    MatDatepickerModule,
    MatMomentDateModule,
    AppRoutingModule,
    AppHeaderModule,
    ClaimsModule,
    FleetsModule,
    UnitsModule,
    PoliciesModule,
    EndorsementsModule,
    ReportsModule,
    NotificationsModule,
    CertificatesModule,
    ProcessesModule,
    DashboardsModule,
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    HowdenAlertsModule,
    HowdenCoreModule.forRoot(environment as IHowdenEnvironment, packageJson as IPackageJsonWrapper, ApiConfigurations)
  ],
  providers: [
    NotificationsService,
    DecimalPipe,
    CurrencyPipe,
    PercentPipe,
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
    { provide: LOCALE_ID, useFactory: getLocaleId, deps: [HowdenUserService] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, autoFocus: true, restoreFocus: true } },
    provideNgxWebstorage(
      withNgxWebstorageConfig({ separator: ':', caseSensitive: true }),
      withLocalStorage(),
      withSessionStorage()
    ),
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(private notificationSrv: NotificationsService) {
    this.notificationSrv.pushNotification({
      id: 'fleet-notification-count',
      icon: 'notifications_active',
      number: 0,
      tooltip: $localize`:@@app.subheader.notifications.fleet.notification.count.tooltip:Notificaciones flotas`
    });

    this.notificationSrv.pushNotification({
      id: 'waiting-process-count',
      icon: 'memory',
      number: 0,
      tooltip: $localize`:@@app.subheader.notifications.waiting.process.count.tooltip:Procesos pendientes de ejecución`
    });
  }
}
