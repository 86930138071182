import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IFileTemplateRequest } from 'src/app/core/models/api/templates';
import { ISessionService } from '../../storage/session';
import { IFileTemplatesService } from './file-templates.contract';

@Injectable({
  providedIn: 'root'
})
export class FileTemplatesService implements IFileTemplatesService {
  constructor(private httpClient: HowdenHttpClientService, private sessionSrv: ISessionService) {
  }

  getUpdateFleetTemplate(): Observable<Blob> {
    const uri = `/file-templates/update-fleet`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getInitialStateTemplate(): Observable<Blob> {
    const uri = `/file-templates/initial-state`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getCreateUnitsTemplate(): Observable<Blob> {
    const uri = `/file-templates/create-units`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getUpdateUnitsTemplate(): Observable<Blob> {
    const uri = `/file-templates/update-units`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getParalizationsTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-paralizations`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyAssignationTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-assignation`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyRenumberingTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-renumbering`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyLoadTemplate(request: IFileTemplateRequest): Observable<Blob> {
    const uri = `/file-templates/policy-load`;
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyUpdateTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-update`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getRootPolicyUpdateTemplate(): Observable<Blob> {
    const uri = `/file-templates/root-policy-update`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyTransferTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-transfer`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyCancellationTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-cancellation`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyBonusUpdateTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-bonus-update`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyHolderUpdateTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-holder-update`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyRiskUpdateTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-risk-update`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getRateChangeTemplate(): Observable<Blob> {
    const uri = `/file-templates/rate-change`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyRiskBookingModeChangeWithEndorsementTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-risk-booking-mode-change-with-endorsement`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getPolicyRiskBookingModeChangeWithoutEndorsementTemplate(): Observable<Blob> {
    const uri = `/file-templates/policy-risk-booking-mode-change-without-endorsement`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  getEndorsementLoaderTemplate(): Observable<Blob> {
    const uri = `/file-templates/endorsement-loader`;
    const request = this.getDefaultRequest();
    return this.httpClient.postRespBlob(APP_CONSTANTS.API_NET_FLOTAS, uri, request, {
      responseType: 'blob'
    });
  }

  private getDefaultRequest(): IFileTemplateRequest {
    return {
      fleetId: this.sessionSrv.hasActiveFleet ? this.sessionSrv.activeFleet : undefined
    } as IFileTemplateRequest;
  }
}
