import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFileTemplatesService } from 'src/app/core/services/api/templates';
import { ISessionService } from 'src/app/core/services/storage/session';
import { FileUtils } from 'src/app/shared/utils';
import { SubSink } from 'subsink';
import { GeneratePolicyLoadTemplateDialogComponent } from '../components/generate-policy-load-template-dialog/pages/generate-policy-load-template-dialog.component';

@Component({
  selector: 'howden-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnDestroy {
  private _subscriptions = new SubSink();

  constructor(
    private dialog: MatDialog,
    private sessionSrv: ISessionService,
    private templatesSrv: IFileTemplatesService
  ) {
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  get hasActiveFleet(): boolean {
    return this.sessionSrv.hasActiveFleet;
  }

  downloadIntialStateTemplate() {
    this.templatesSrv.getInitialStateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_FOTO_INICIAL', blob);
    });
  }

  downloadUpdateFleetTemplate() {
    this.templatesSrv.getUpdateFleetTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_PLANTILLA_ACTUALIZACION_FLOTA', blob);
    });
  }

  downloadCreateUnitsTemplate() {
    this.templatesSrv.getCreateUnitsTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_PLANTILLA_CARGA_UNIDADES', blob);
    });
  }

  downloadUpdateUnitsTemplate() {
    this.templatesSrv.getUpdateUnitsTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_PLANTILLA_ACTUALIZACION_UNIDADES', blob);
    });
  }

  downloadParalizationsTemplate() {
    this.templatesSrv.getParalizationsTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_PLANTILLA_PARALIZACIONES', blob);
    });
  }

  downloadPolicyAssignationTemplate() {
    this.templatesSrv.getPolicyAssignationTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ASIGNACION_POLIZAS', blob);
    });
  }

  downloadPolicyRenumberingTemplate() {
    this.templatesSrv.getPolicyRenumberingTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_RENUMERACION_POLIZAS', blob);
    });
  }

  downloadPolicyUpdateTemplate() {
    this.templatesSrv.getPolicyUpdateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ACTUALIZACION_POLIZAS', blob);
    });
  }

  downloadRootPolicyUpdateTemplate() {
    this.templatesSrv.getRootPolicyUpdateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CAMBIO_DE_POLIZA_MADRE', blob);
    });
  }

  downloadPolicyTransferTemplate() {
    this.templatesSrv.getPolicyTransferTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_TRASPASO_DE_POLIZAS', blob);
    });
  }

  downloadPolicyCancellationTemplate() {
    this.templatesSrv.getPolicyCancellationTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ANULACION_POLIZAS', blob);
    });
  }

  downloadPolicyBonusUpdateTemplate() {
    this.templatesSrv.getPolicyBonusUpdateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ACTUALIZACION_PRIMAS', blob);
    });
  }

  downloadPolicyHolderUpdateTemplate() {
    this.templatesSrv.getPolicyHolderUpdateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CAMBIO_DE_TOMADOR', blob);
    });
  }

  downloadPolicyRiskUpdateTemplate() {
    this.templatesSrv.getPolicyRiskUpdateTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ACTUALIZACION_RIESGOS_POLIZAS', blob);
    });
  }

  downloadRateChangeTemplate() {
    this.templatesSrv.getRateChangeTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CAMBIO_TARIFA', blob);
    });
  }

  downloadPolicyRiskBookingModeChangeWithEndorsementTemplate() {
    this.templatesSrv.getPolicyRiskBookingModeChangeWithEndorsementTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CAMBIO_MODO_CONTRATACION_CON_SUPLEMENTO', blob);
    });
  }

  downloadPolicyRiskBookingModeChangeWithoutEndorsementTemplate() {
    this.templatesSrv.getPolicyRiskBookingModeChangeWithoutEndorsementTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_CAMBIO_MODO_CONTRATACION_SIN_SUPLEMENTO', blob);
    });
  }

  downloadEndorsementLoaderTemplate() {
    this.templatesSrv.getEndorsementLoaderTemplate().subscribe(blob => {
      FileUtils.downloadFile('HOWDEN_NET_FLOTAS_ALTA_DE_SUPLEMENTOS', blob);
    });
  }

  generatePolicyLoadTemplate() {
    const dialogRef = this.dialog.open(GeneratePolicyLoadTemplateDialogComponent, {
      minWidth: '1200px',
      restoreFocus: false
    });

    this._subscriptions.sink = dialogRef.afterClosed().subscribe();
  }
}
