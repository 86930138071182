import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { IHowdenColumnConfig, ITableButtonConfig } from '@howdeniberia/core-front';
import { Observable, map, startWith } from 'rxjs';
import { IBookingModeSearchResult } from 'src/app/core/models/api/booking-modes';
import { IBookingModeChangerFileItem } from 'src/app/core/models/api/fleets';
import { ICurrency } from 'src/app/core/models/ida/financial';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry, IProduct } from 'src/app/core/models/ida/products';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';
import { Limits } from 'src/app/shared/utils';
import { forbiddenNamesValidator } from 'src/app/shared/validators';

export class BookingModeChangerWithEndorsementViewModel {
  private _form: FormGroup;
  private _insuranceCompanies: Array<IInsuranceCompany> = [];
  private _industries: Array<IIndustry> = [];
  private _products: Array<IProduct> = [];
  private _bookingModes: Array<IBookingModeSearchResult> = [];
  private _endorsementStatuses: Array<IMasterValue> = [];
  private _endorsementTypes: Array<IMasterValue> = [];
  private _receiptSituations: Array<IMasterValue> = [];
  private _receiptTypes: Array<IMasterValue> = [];
  private _currencies: Array<ICurrency> = [];

  private _filteredInsuranceCompanies: Observable<Array<IInsuranceCompany>>;
  private _filteredIndustries: Observable<Array<IIndustry>>;
  private _filteredProducts: Observable<Array<IProduct>>;
  private _filteredBookingModes: Observable<Array<IBookingModeSearchResult>>;

  readonly C_OPERATION_DATE = 'operationDate';
  readonly C_INSURANCE_COMPANY_ID = 'insuranceCompanyId';
  readonly C_INDUSTRY_ID = 'industryId';
  readonly C_PRODUCT_ID = 'productId';
  readonly C_BOOKING_MODE_ID = 'bookingModeId';
  readonly C_NET_BONUS = 'netBonus';
  readonly C_TOTAL_BONUS = 'totalBonus';
  readonly C_COMMISSION_RATE = 'commissionRate';
  readonly C_FRANCHISE_DP = 'franchiseDP';
  readonly C_FRANCHISE_TR = 'franchiseTR';
  readonly C_FRANCHISE_I = 'franchiseI';
  readonly C_FRANCHISE_R = 'franchiseR';
  readonly C_FRANCHISE_L = 'franchiseL';
  readonly C_FILE = 'file';

  readonly C_ENDORSEMENT_TYPE_ID = 'endorsementTypeId';
  readonly C_ENDORSEMENT_STATUS_ID = 'endorsementStatusId';
  readonly C_ENDORSEMENT_ALIAS = 'endorsementAlias';
  readonly C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE = 'endorsementInsuranceCompanyReference';
  readonly C_ENDORSEMENT_DESCRIPTION = 'endorsementDescription';
  readonly C_ENDORSEMENT_OBSERVATIONS = 'endorsementObservations';
  readonly C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY = 'endorsementDateSentToInsuranceCompany';
  readonly C_ENDORSEMENT_REQUEST_DATE = 'endorsementRequestDate';
  readonly C_ENDORSEMENT_RECEPTION_DATE = 'endorsementReceptionDate';
  readonly C_ENDORSEMENT_EFFECT_DATE = 'endorsementEffectDate';
  readonly C_ENDORSEMENT_EXPIRATION_DATE = 'endorsementExpirationDate';
  readonly C_ENDORSEMENT_CANCELLATION_DATE = 'endorsementCancellationDate';
  readonly C_ENDORSEMENT_CREATES_RECEIPT = 'createsReceipt';
  readonly C_ENDORSEMENT_OLD_INFO = 'endorsementOldInformation';
  readonly C_ENDORSEMENT_NEW_INFO = 'endorsementNewInformation';

  readonly C_RECEIPT_CONCEPT = 'receiptConcept';
  readonly C_RECEIPT_TYPE_ID = 'receiptTypeId';
  readonly C_RECEIPT_SITUATION_ID = 'receiptSituationId';
  readonly C_RECEIPT_CURRENCY_ID = 'receiptCurrencyId';
  readonly C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER = 'receiptInsuranceCompanyReceiptNumber';
  readonly C_RECEIPT_NUMBER = 'receiptNumber';
  readonly C_RECEIPT_PAYMENT_TYPE = 'receiptPaymentType';
  readonly C_RECEIPT_BROKER_ISSUE_DATE = 'receiptBrokerIssueDate';
  readonly C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE = 'receiptInsuranceCompanyIssueDate';
  readonly C_RECEIPT_EFFECT_DATE = 'receiptEffectDate';
  readonly C_RECEIPT_EXPIRATION_DATE = 'receiptExpirationDate';
  readonly C_RECEIPT_COLLECTION_DATE = 'receiptCollectionDate';
  readonly C_RECEIPT_REBATE_DATE = 'receiptRebateDate';
  readonly C_RECEIPT_SETTLEMENT_DATE = 'receiptSettlementDate';
  readonly C_RECEIPT_RESOLUTION_DATE = 'receiptResolutionDate';
  readonly C_RECEIPT_NET_BONUS = 'receiptNetBonus';
  readonly C_RECEIPT_TOTAL_BONUS = 'receiptTotalBonus';
  readonly C_RECEIPT_SURCHARGE = 'receiptSurcharge';
  readonly C_RECEIPT_EXTERNAL_SURCHARGE = 'receiptExternalSurcharge';
  readonly C_RECEIPT_TAXES = 'receiptTaxes';
  readonly C_RECEIPT_CONSORTIUM_TAXES = 'receiptConsortiumTaxes';
  readonly C_RECEIPT_BONUS_PAY_AMOUNT = 'receiptBonusPayAmount';

  constructor() {
    this._form = new FormGroup({
      [this.C_OPERATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_INSURANCE_COMPANY_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.insuranceCompanies, 'insuranceCompanyId')]),
      [this.C_INDUSTRY_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.industries, 'industryId')]),
      [this.C_PRODUCT_ID]: new FormControl('', [Validators.required, forbiddenNamesValidator(() => this.products, 'productId')]),
      [this.C_BOOKING_MODE_ID]: new FormControl(null, [Validators.required]),
      [this.C_NET_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_TOTAL_BONUS]: new FormControl(null, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_COMMISSION_RATE]: new FormControl(null, [Validators.min(0), Validators.max(100)]),
      [this.C_FRANCHISE_DP]: new FormControl(null, []),
      [this.C_FRANCHISE_TR]: new FormControl(null, []),
      [this.C_FRANCHISE_I]: new FormControl(null, []),
      [this.C_FRANCHISE_R]: new FormControl(null, []),
      [this.C_FRANCHISE_L]: new FormControl(null, []),
      [this.C_FILE]: new FormControl(null, [Validators.required]),

      [this.C_ENDORSEMENT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_STATUS_ID]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_ALIAS]: new FormControl(null, []),
      [this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE]: new FormControl(null, []),
      [this.C_ENDORSEMENT_DESCRIPTION]: new FormControl(null, []),
      [this.C_ENDORSEMENT_OBSERVATIONS]: new FormControl(null, []),
      [this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY]: new FormControl(null, []),
      [this.C_ENDORSEMENT_REQUEST_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_RECEPTION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_ENDORSEMENT_CANCELLATION_DATE]: new FormControl(null, []),
      [this.C_ENDORSEMENT_CREATES_RECEIPT]: new FormControl(null, []),
      [this.C_ENDORSEMENT_OLD_INFO]: new FormControl(null, []),
      [this.C_ENDORSEMENT_NEW_INFO]: new FormControl(null, []),

      [this.C_RECEIPT_CONCEPT]: new FormControl(null, []),
      [this.C_RECEIPT_TYPE_ID]: new FormControl(null, [Validators.required]),
      [this.C_RECEIPT_SITUATION_ID]: new FormControl(null, [Validators.required]),
      [this.C_RECEIPT_CURRENCY_ID]: new FormControl(null, []),
      [this.C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER]: new FormControl(null, []),
      [this.C_RECEIPT_NUMBER]: new FormControl(null, []),
      [this.C_RECEIPT_PAYMENT_TYPE]: new FormControl(null, []),
      [this.C_RECEIPT_BROKER_ISSUE_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_EFFECT_DATE]: new FormControl(null, [Validators.required]),
      [this.C_RECEIPT_EXPIRATION_DATE]: new FormControl(null, [Validators.required]),
      [this.C_RECEIPT_COLLECTION_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_REBATE_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_SETTLEMENT_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_RESOLUTION_DATE]: new FormControl(null, []),
      [this.C_RECEIPT_NET_BONUS]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_TOTAL_BONUS]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_SURCHARGE]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_EXTERNAL_SURCHARGE]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_TAXES]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_CONSORTIUM_TAXES]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)]),
      [this.C_RECEIPT_BONUS_PAY_AMOUNT]: new FormControl(0, [Validators.min(Limits.zero), Validators.max(Limits.maxInt32)])
    });

    this._filteredInsuranceCompanies = this.getControl(this.C_INSURANCE_COMPANY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterInsuranceCompanies(value || ''))
    );

    this._filteredIndustries = this.getControl(this.C_INDUSTRY_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterIndustries(value || ''))
    );

    this._filteredProducts = this.getControl(this.C_PRODUCT_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterProducts(value || ''))
    );

    this._filteredBookingModes = this.getControl(this.C_BOOKING_MODE_ID).valueChanges.pipe(
      startWith(''),
      map(value => this.filterBookingModes(value || ''))
    );
  }

  disableReceiptStep() {
    this.getControl(this.C_RECEIPT_CONCEPT).disable();
    this.getControl(this.C_RECEIPT_TYPE_ID).disable();
    this.getControl(this.C_RECEIPT_SITUATION_ID).disable();
    this.getControl(this.C_RECEIPT_CURRENCY_ID).disable();
    this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER).disable();
    this.getControl(this.C_RECEIPT_NUMBER).disable();
    this.getControl(this.C_RECEIPT_PAYMENT_TYPE).disable();
    this.getControl(this.C_RECEIPT_BROKER_ISSUE_DATE).disable();
    this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE).disable();
    this.getControl(this.C_RECEIPT_EFFECT_DATE).disable();
    this.getControl(this.C_RECEIPT_EXPIRATION_DATE).disable();
    this.getControl(this.C_RECEIPT_COLLECTION_DATE).disable();
    this.getControl(this.C_RECEIPT_REBATE_DATE).disable();
    this.getControl(this.C_RECEIPT_SETTLEMENT_DATE).disable();
    this.getControl(this.C_RECEIPT_RESOLUTION_DATE).disable();
    this.getControl(this.C_RECEIPT_NET_BONUS).disable();
    this.getControl(this.C_RECEIPT_TOTAL_BONUS).disable();
    this.getControl(this.C_RECEIPT_SURCHARGE).disable();
    this.getControl(this.C_RECEIPT_EXTERNAL_SURCHARGE).disable();
    this.getControl(this.C_RECEIPT_TAXES).disable();
    this.getControl(this.C_RECEIPT_CONSORTIUM_TAXES).disable();
    this.getControl(this.C_RECEIPT_BONUS_PAY_AMOUNT).disable();
  }

  enableReceiptStep() {
    this.getControl(this.C_RECEIPT_CONCEPT).enable();
    this.getControl(this.C_RECEIPT_TYPE_ID).enable();
    this.getControl(this.C_RECEIPT_SITUATION_ID).enable();
    this.getControl(this.C_RECEIPT_CURRENCY_ID).enable();
    this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER).enable();
    this.getControl(this.C_RECEIPT_NUMBER).enable();
    this.getControl(this.C_RECEIPT_PAYMENT_TYPE).enable();
    this.getControl(this.C_RECEIPT_BROKER_ISSUE_DATE).enable();
    this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE).enable();
    this.getControl(this.C_RECEIPT_EFFECT_DATE).enable();
    this.getControl(this.C_RECEIPT_EXPIRATION_DATE).enable();
    this.getControl(this.C_RECEIPT_COLLECTION_DATE).enable();
    this.getControl(this.C_RECEIPT_REBATE_DATE).enable();
    this.getControl(this.C_RECEIPT_SETTLEMENT_DATE).enable();
    this.getControl(this.C_RECEIPT_RESOLUTION_DATE).enable();
    this.getControl(this.C_RECEIPT_NET_BONUS).enable();
    this.getControl(this.C_RECEIPT_TOTAL_BONUS).enable();
    this.getControl(this.C_RECEIPT_SURCHARGE).enable();
    this.getControl(this.C_RECEIPT_EXTERNAL_SURCHARGE).enable();
    this.getControl(this.C_RECEIPT_TAXES).enable();
    this.getControl(this.C_RECEIPT_CONSORTIUM_TAXES).enable();
    this.getControl(this.C_RECEIPT_BONUS_PAY_AMOUNT).enable();
  }

  get insuranceCompanies(): Array<IInsuranceCompany> {
    return this._insuranceCompanies;
  }

  set insuranceCompanies(value: Array<IInsuranceCompany>) {
    this._insuranceCompanies = value;
  }

  get industries(): Array<IIndustry> {
    return this._industries;
  }

  set industries(value: Array<IIndustry>) {
    this._industries = value;
  }

  get products(): Array<IProduct> {
    return this._products;
  }

  set products(value: Array<IProduct>) {
    this._products = value;
  }

  get bookingModes(): Array<IBookingModeSearchResult> {
    return this._bookingModes;
  }

  set bookingModes(values: Array<IBookingModeSearchResult>) {
    this._bookingModes = values;
  }

  get endorsementStatuses(): Array<IMasterValue> {
    return this._endorsementStatuses;
  }

  set endorsementStatuses(values: Array<IMasterValue>) {
    this._endorsementStatuses = values;
  }

  get endorsementTypes(): Array<IMasterValue> {
    return this._endorsementTypes;
  }

  set endorsementTypes(values: Array<IMasterValue>) {
    this._endorsementTypes = values;
  }

  get receiptSituations(): Array<IMasterValue> {
    return this._receiptSituations;
  }

  set receiptSituations(values: Array<IMasterValue>) {
    this._receiptSituations = values;
  }

  get receiptTypes(): Array<IMasterValue> {
    return this._receiptTypes;
  }

  set receiptTypes(values: Array<IMasterValue>) {
    this._receiptTypes = values;
  }

  get currencies(): Array<ICurrency> {
    return this._currencies;
  }

  set currencies(values: Array<ICurrency>) {
    this._currencies = values;
  }

  getControl(controlName: string): AbstractControl<any, any> {
    return this._form.get(controlName)!;
  }

  get form(): FormGroup {
    return this._form;
  }

  /** OPERATION */

  get operationDate(): Date | undefined {
    return this.getControl(this.C_OPERATION_DATE).value;
  }

  set operationDate(value: Date | undefined) {
    this.getControl(this.C_OPERATION_DATE).setValue(value);
  }

  get insuranceCompanyId(): string {
    return this.getControl(this.C_INSURANCE_COMPANY_ID).value;
  }

  set insuranceCompanyId(value: string) {
    this.getControl(this.C_INSURANCE_COMPANY_ID).setValue(value);
  }

  get industryId(): string {
    return this.getControl(this.C_INDUSTRY_ID).value;
  }

  set industryId(value: string) {
    this.getControl(this.C_INDUSTRY_ID).setValue(value);
  }

  get productId(): string {
    return this.getControl(this.C_PRODUCT_ID).value;
  }

  set productId(value: string) {
    this.getControl(this.C_PRODUCT_ID).setValue(value);
  }

  get bookingModeId(): string | undefined {
    return this.getControl(this.C_BOOKING_MODE_ID).value;
  }

  set bookingModeId(value: string | undefined) {
    this.getControl(this.C_BOOKING_MODE_ID).setValue(value);
  }

  get netBonus(): number | undefined {
    return this.getControl(this.C_NET_BONUS).value;
  }

  set netBonus(value: number | undefined) {
    this.getControl(this.C_NET_BONUS).setValue(value);
  }

  get totalBonus(): number | undefined {
    return this.getControl(this.C_TOTAL_BONUS).value;
  }

  set totalBonus(value: number | undefined) {
    this.getControl(this.C_TOTAL_BONUS).setValue(value);
  }

  get commissionRate(): number | undefined {
    return this.getControl(this.C_COMMISSION_RATE).value;
  }

  set commissionRate(value: number | undefined) {
    this.getControl(this.C_COMMISSION_RATE).setValue(value);
  }

  get franchiseDP(): string | undefined {
    return this.getControl(this.C_FRANCHISE_DP).value;
  }

  set franchiseDP(value: string | undefined) {
    this.getControl(this.C_FRANCHISE_DP).setValue(value);
  }

  get franchiseTR(): string | undefined {
    return this.getControl(this.C_FRANCHISE_TR).value;
  }

  set franchiseTR(value: string | undefined) {
    this.getControl(this.C_FRANCHISE_TR).setValue(value);
  }

  get franchiseI(): string | undefined {
    return this.getControl(this.C_FRANCHISE_I).value;
  }

  set franchiseI(value: string | undefined) {
    this.getControl(this.C_FRANCHISE_I).setValue(value);
  }

  get franchiseR(): string | undefined {
    return this.getControl(this.C_FRANCHISE_R).value;
  }

  set franchiseR(value: string | undefined) {
    this.getControl(this.C_FRANCHISE_R).setValue(value);
  }

  get franchiseL(): string | undefined {
    return this.getControl(this.C_FRANCHISE_L).value;
  }

  set franchiseL(value: string | undefined) {
    this.getControl(this.C_FRANCHISE_L).setValue(value);
  }

  /** ENDORSEMENT */

  get endorsementTypeId(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_TYPE_ID).value;
  }

  set endorsementTypeId(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_TYPE_ID).setValue(value);
  }

  get endorsementStatusId(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_STATUS_ID).value;
  }

  set endorsementStatusId(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_STATUS_ID).setValue(value);
  }

  get endorsementAlias(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_ALIAS).value;
  }

  set endorsementAlias(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_ALIAS).setValue(value);
  }

  get endorsementInsuranceCompanyReference(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE).value;
  }

  set endorsementInsuranceCompanyReference(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_INSURANCE_COMPANY_REFERENCE).setValue(value);
  }

  get endorsementDescription(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_DESCRIPTION).value;
  }

  set endorsementDescription(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_DESCRIPTION).setValue(value);
  }

  get endorsementObservations(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_OBSERVATIONS).value;
  }

  set endorsementObservations(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_OBSERVATIONS).setValue(value);
  }

  get endorsementDateSentToInsuranceCompany(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY).value;
  }

  set endorsementDateSentToInsuranceCompany(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_DATE_SENT_TO_INSURANCE_COMPANY).setValue(value);
  }

  get endorsementRequestDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_REQUEST_DATE).value;
  }

  set endorsementRequestDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_REQUEST_DATE).setValue(value);
  }

  get endorsementReceptionDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_RECEPTION_DATE).value;
  }

  set endorsementReceptionDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_RECEPTION_DATE).setValue(value);
  }

  get endorsementEffectDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_EFFECT_DATE).value;
  }

  set endorsementEffectDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_EFFECT_DATE).setValue(value);
  }

  get endorsementExpirationDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_EXPIRATION_DATE).value;
  }

  set endorsementExpirationDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_EXPIRATION_DATE).setValue(value);
  }

  get endorsementCancellationDate(): Date | undefined {
    return this.getControl(this.C_ENDORSEMENT_CANCELLATION_DATE).value;
  }

  set endorsementCancellationDate(value: Date | undefined) {
    this.getControl(this.C_ENDORSEMENT_CANCELLATION_DATE).setValue(value);
  }

  get createsReceipt(): boolean {
    return this.getControl(this.C_ENDORSEMENT_CREATES_RECEIPT).value;
  }

  set createsReceipt(value: boolean) {
    this.getControl(this.C_ENDORSEMENT_CREATES_RECEIPT).setValue(value);
  }

  get endorsementOldInformation(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_OLD_INFO).value;
  }

  set endorsementOldInformation(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_OLD_INFO).setValue(value);
  }

  get endorsementNewInformation(): string | undefined {
    return this.getControl(this.C_ENDORSEMENT_NEW_INFO).value;
  }

  set endorsementNewInformation(value: string | undefined) {
    this.getControl(this.C_ENDORSEMENT_NEW_INFO).setValue(value);
  }

  /** RECEIPT */

  get receiptConcept(): string | undefined {
    return this.getControl(this.C_RECEIPT_CONCEPT).value;
  }

  get receiptTypeId(): string | undefined {
    return this.getControl(this.C_RECEIPT_TYPE_ID).value;
  }

  get receiptSituationId(): string | undefined {
    return this.getControl(this.C_RECEIPT_SITUATION_ID).value;
  }

  get receiptCurrencyId(): string | undefined {
    return this.getControl(this.C_RECEIPT_CURRENCY_ID).value;
  }

  get receiptInsuranceCompanyReceiptNumber(): string | undefined {
    return this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_RECEIPT_NUMBER).value;
  }

  get receiptNumber(): string | undefined {
    return this.getControl(this.C_RECEIPT_NUMBER).value;
  }

  get receiptPaymentType(): string | undefined {
    return this.getControl(this.C_RECEIPT_PAYMENT_TYPE).value;
  }

  get receiptBrokerIssueDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_BROKER_ISSUE_DATE).value;
  }

  get receiptInsuranceCompanyIssueDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_INSURANCE_COMPANY_ISSUE_DATE).value;
  }

  get receiptEffectDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_EFFECT_DATE).value;
  }

  get receiptExpirationDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_EXPIRATION_DATE).value;
  }

  get receiptCollectionDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_COLLECTION_DATE).value;
  }

  get receiptRebateDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_REBATE_DATE).value;
  }

  get receiptSettlementDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_SETTLEMENT_DATE).value;
  }

  get receiptResolutionDate(): Date | undefined {
    return this.getControl(this.C_RECEIPT_RESOLUTION_DATE).value;
  }

  get receiptNetBonus(): number | undefined {
    return this.getControl(this.C_RECEIPT_NET_BONUS).value;
  }

  get receiptTotalBonus(): number | undefined {
    return this.getControl(this.C_RECEIPT_TOTAL_BONUS).value;
  }

  get receiptSurcharge(): number | undefined {
    return this.getControl(this.C_RECEIPT_SURCHARGE).value;
  }

  get receiptExternalSurcharge(): number | undefined {
    return this.getControl(this.C_RECEIPT_EXTERNAL_SURCHARGE).value;
  }

  get receiptTaxes(): number | undefined {
    return this.getControl(this.C_RECEIPT_TAXES).value;
  }

  get receiptConsortiumTaxes(): number | undefined {
    return this.getControl(this.C_RECEIPT_CONSORTIUM_TAXES).value;
  }

  get receiptBonusPayAmount(): number | undefined {
    return this.getControl(this.C_RECEIPT_BONUS_PAY_AMOUNT).value;
  }

  get filteredInsuranceCompanies(): Observable<Array<IInsuranceCompany>> {
    return this._filteredInsuranceCompanies;
  }

  get filteredIndustries(): Observable<Array<IIndustry>> {
    return this._filteredIndustries;
  }

  get filteredProducts(): Observable<Array<IProduct>> {
    return this._filteredProducts;
  }

  get filteredBookingModes(): Observable<Array<IBookingModeSearchResult>> {
    return this._filteredBookingModes;
  }

  private filterInsuranceCompanies(value: string): Array<IInsuranceCompany> {
    return value !== ''
      ? this._insuranceCompanies.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._insuranceCompanies;
  }

  private filterIndustries(value: string): Array<IIndustry> {
    return value !== ''
      ? this._industries.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._industries;
  }

  private filterProducts(value: string): Array<IProduct> {
    return value !== ''
      ? this._products.filter(x => x.alias?.toLowerCase().includes(value.toLowerCase()))
      : this._products;
  }

  private filterBookingModes(value: string): Array<IBookingModeSearchResult> {
    return value !== ''
      ? this.bookingModes.filter(c => c.description?.toLowerCase().includes(value.toLowerCase()))
      : this.bookingModes;
  }

  updaterResults: IBookingModeChangerFileItem[] = [];
  updaterResultsColumns: Array<IHowdenColumnConfig> = [
    {
      fieldName: 'insuranceCompany',
      fieldHeader: $localize`:@@app.policies.booking.mode.changer.with.endorsement.insuranceCompany.column:Compañía`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'policyNumber',
      fieldHeader: $localize`:@@app.policies.booking.mode.changer.with.endorsement.policyNumber.column:Nº. Póliza`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'riskAlias',
      fieldHeader: $localize`:@@app.policies.booking.mode.changer.with.endorsement.riskAlias.column:Riesgo`,
      canSort: true,
      width: '150px'
    },
    {
      fieldName: 'errors',
      fieldHeader: $localize`:@@app.policies.booking.mode.changer.with.endorsement.errors.column:Errores`,
      canSort: true
    }
  ];

  buttons: Array<ITableButtonConfig> = [];
}
