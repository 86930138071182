import { IPageOf, IResponse } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import {
  IAssignFleetCompany,
  IBookingModeChangerResponse,
  ICreateFleetResponse,
  IEndorsementLoaderResponse,
  IFleet,
  IFleetCompany,
  IFleetSearchRequest,
  IFleetSearchResult,
  IFleetSetting,
  IFleetTax,
  IFleetUpdaterResponse,
  IInitialStateLoaderResponse,
  IInsurerMergerItem,
  IInsurerMergerRequest,
  IParalizationDaysLoaderResponse,
  IPolicyBonusUpdaterResponse,
  IPolicyCancellerResponse,
  IPolicyHolderUpdaterResponse,
  IPolicyRenew,
  IPolicyRenumberingResponse,
  IPolicyReplacerResponse,
  IPolicyRiskUpdaterResponse,
  IPolicyUpdaterResponse,
  IRateChangerResponse,
  IRootPolicyUpdaterResponse,
  IUnitLoaderResponse,
  IUnitUpdaterResponse,
  IUpdateFleet,
  IUpdateFleetSettings
} from 'src/app/core/models/api/fleets';
import { IGlobalSearchResult } from 'src/app/core/models/api/global-search';
import { IPolicyLoaderError } from 'src/app/core/models/api/policies';
import { IClient } from 'src/app/core/models/ida/clients';
import { IMasterValue } from 'src/app/core/models/ida/masters';
import { IIndustry } from 'src/app/core/models/ida/products';
import { IInsuranceCompany } from 'src/app/core/models/ida/providers';

export abstract class IFleetService {
  abstract downloadHelpManual(): Observable<Blob>;
  abstract globalSearch(query: string | null): Observable<Array<IGlobalSearchResult>>;
  abstract search(request: IFleetSearchRequest): Observable<IPageOf<IFleetSearchResult>>;
  abstract get(id: string): Observable<IFleet>;
  abstract getDetails(id: string): Observable<IFleetSearchResult>;
  abstract create(request: FormData): Observable<ICreateFleetResponse>;
  abstract update(id: string, request: IUpdateFleet): Observable<void>;
  abstract delete(id: string): Observable<void>;
  abstract assignCompany(id: string, request: IAssignFleetCompany): Observable<void>;
  abstract getCompanies(id: string): Observable<Array<IFleetCompany>>;
  abstract getNotAssignedCompanies(id: string): Observable<Array<IClient>>;
  abstract getInsuranceCompanies(id: string): Observable<Array<IInsuranceCompany>>;
  abstract getIndustries(id: string): Observable<Array<IIndustry>>;
  abstract getVehicleTypes(id: string): Observable<Array<IMasterValue>>;
  abstract getVehicleUsages(id: string): Observable<Array<IMasterValue>>;
  abstract isBookingModeRequired(id: string): Observable<IResponse<boolean>>;
  abstract hasNonRenewedBonuses(id: string): Observable<IResponse<boolean>>;
  abstract updateFleetSettings(id: string, settings: IUpdateFleetSettings): Observable<void>;
  abstract getInsuranceCompanySettings(id: string): Observable<Array<IFleetSetting>>;
  abstract getIndustrySettings(id: string): Observable<Array<IFleetSetting>>;
  abstract getVehicleTypeSettings(id: string): Observable<Array<IFleetSetting>>;
  abstract getVehicleUsageSettings(id: string): Observable<Array<IFleetSetting>>;
  abstract bookingModeChangerWithEndorsement(id: string, request: FormData): Observable<IBookingModeChangerResponse>;
  abstract bookingModeChangerWithoutEndorsement(id: string, request: FormData): Observable<IBookingModeChangerResponse>;
  abstract loadParalizationDays(id: string, request: FormData): Observable<IParalizationDaysLoaderResponse>;
  abstract policyBonusUpdater(id: string, request: FormData): Observable<IPolicyBonusUpdaterResponse>;
  abstract policyCanceller(id: string, request: FormData): Observable<IPolicyCancellerResponse>;
  abstract policyHolderUpdater(id: string, request: FormData): Observable<IPolicyHolderUpdaterResponse>;
  abstract policyRenewer(id: string, request: IPolicyRenew): Observable<void>;
  abstract policyRenumbering(id: string, request: FormData): Observable<IPolicyRenumberingResponse>;
  abstract policyReplacer(id: string, request: FormData): Observable<IPolicyReplacerResponse>;
  abstract policyRiskUpdater(id: string, request: FormData): Observable<IPolicyRiskUpdaterResponse>;
  abstract rateChanger(id: string, request: FormData): Observable<IRateChangerResponse>;
  abstract policyLoader(id: string, request: FormData): Observable<Array<IPolicyLoaderError>>;
  abstract policyLoaderValidation(id: string, request: FormData): Observable<Array<IPolicyLoaderError>>;
  abstract policyUpdater(id: string, request: FormData): Observable<IPolicyUpdaterResponse>;
  abstract rootPolicyUpdater(id: string, request: FormData): Observable<IRootPolicyUpdaterResponse>;
  abstract unitLoader(id: string, request: FormData): Observable<IUnitLoaderResponse>;
  abstract unitUpdater(id: string, request: FormData): Observable<IUnitUpdaterResponse>;
  abstract endorsementLoader(id: string, request: FormData): Observable<IEndorsementLoaderResponse>;
  abstract fleetUpdater(id: string, request: FormData): Observable<IFleetUpdaterResponse>;
  abstract initialStateLoader(id: string, request: FormData): Observable<IInitialStateLoaderResponse>;

  abstract getTaxes(id: string): Observable<Array<IFleetTax>>;
  abstract getTax(id: string, taxId: string): Observable<IFleetTax>;
  abstract upsertTax(id: string, request: IFleetTax): Observable<void>;
  abstract deleteTax(id: string, taxId: string): Observable<void>;

  abstract insurerMerger(request: IInsurerMergerRequest): Observable<void>;
  abstract insurerMergerPreview(request: IInsurerMergerRequest): Observable<Array<IInsurerMergerItem>>;
}
