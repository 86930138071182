<button
  type="button"
  color="secondary"
  mat-mini-fab
  matTooltip="Descarga de plantillas"
  i18n-matTooltip="@@app.header.templates.general.tooltip.label"
  aria-label="Descarga de plantillas"
  i18n-aria-label="@@app.header.templates.general.arial.label"
  [matMenuTriggerFor]="menu"
>
  <mat-icon>download</mat-icon>
</button>

<mat-menu #menu="matMenu">
  @if (hasActiveFleet) {
    <h5 class="heading-style" i18n="@@app.header.templates.fleets.fleets.label">FLOTAS</h5>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadIntialStateTemplate()"
      i18n="@@app.header.templates.fleets.initialState.label">Foto inicial</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadUpdateFleetTemplate()"
      i18n="@@app.header.templates.fleets.update.label">Actualización flota</button>
  }
  @if (hasActiveFleet) {
    <mat-divider></mat-divider>
  }
  @if (hasActiveFleet) {
    <h5 class="heading-style" i18n="@@app.header.templates.units.label">UNIDADES</h5>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadCreateUnitsTemplate()"
      i18n="@@app.header.templates.units.load.label">Carga de unidades</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadUpdateUnitsTemplate()"
      i18n="@@app.header.templates.units.update.label">Actualización de unidades</button>
  }

  @if (hasActiveFleet) {
    <mat-divider></mat-divider>
  }
  <h5 class="heading-style" i18n="@@app.header.templates.policies.label">PÓLIZAS</h5>
  <button
    type="button"
    mat-menu-item
    (click)="downloadParalizationsTemplate()"
    i18n="@@app.header.templates.policies.paralizations.label">Carga de paralizaciones</button>
  <button
    type="button"
    mat-menu-item
    (click)="generatePolicyLoadTemplate()"
    i18n="@@app.header.templates.policies.load.label">Carga de pólizas</button>
  <button
    type="button"
    mat-menu-item
    (click)="downloadPolicyAssignationTemplate()"
    i18n="@@app.header.templates.policies.assignation.label">Asignación de pólizas</button>
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyRenumberingTemplate()"
      i18n="@@app.header.templates.policies.renumeration.label">Renumeración de pólizas</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyUpdateTemplate()"
      i18n="@@app.header.templates.policies.update.label">Actualización de pólizas</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyBonusUpdateTemplate()"
      i18n="@@app.header.templates.policies.bonuses.label">Actualización de primas</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyHolderUpdateTemplate()"
      i18n="@@app.header.templates.policies.holder.update.label">Cambio de tomador</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadRootPolicyUpdateTemplate()"
      i18n="@@app.header.templates.policies.root.policy.update.label">Cambio de póliza madre</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyTransferTemplate()"
      i18n="@@app.header.templates.policies.transfer.label">Traspaso de pólizas</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyCancellationTemplate()"
      i18n="@@app.header.templates.policies.cancellation.label">Baja de pólizas</button>
  }

  @if (hasActiveFleet) {
    <mat-divider></mat-divider>
  }
  @if (hasActiveFleet) {
    <h5 class="heading-style" i18n="@@app.header.templates.policy.risks.label">RIESGOS-PÓLIZAS</h5>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyRiskUpdateTemplate()"
      i18n="@@app.header.templates.policy.risks.update.label">Actualización de riesgos-póliza</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadRateChangeTemplate()"
      i18n="@@app.header.templates.policy.risks.rate.change.label">Cambio de tarifa</button>
  }
  @if (hasActiveFleet) {
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyRiskBookingModeChangeWithEndorsementTemplate()"
      i18n="@@app.header.templates.policy.risks.booking.mode.change.with.endorsement.label">Cambio de modo de contratación (con suplemento)</button>
    <button
      type="button"
      mat-menu-item
      (click)="downloadPolicyRiskBookingModeChangeWithoutEndorsementTemplate()"
      i18n="@@app.header.templates.policy.risks.booking.mode.change.without.endorsement.label">Cambio de modo de contratación (sin suplemento)</button>
  }

  @if (hasActiveFleet) {
    <mat-divider></mat-divider>
  }
  <h5 class="heading-style" i18n="@@app.header.templates.endorsements.label">SUPLEMENTOS</h5>
  <button
    type="button"
    mat-menu-item
    (click)="downloadEndorsementLoaderTemplate()"
    i18n="@@app.header.templates.endorsements.loader.label"
  >
    Alta masiva de suplementos
  </button>
</mat-menu>
