import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@howdeniberia/core-front';
import { ActiveFleetGuard } from 'src/app/core/guards';
import { BookingModeChangerWithEndorsementComponent } from './booking-mode-changer-with-endorsement/pages/booking-mode-changer-with-endorsement.component';
import { BookingModeChangerWithoutEndorsementComponent } from './booking-mode-changer-without-endorsement/pages/booking-mode-changer-without-endorsement.component';
import { PolicyAssignationComponent } from './policy-assignation/pages/policy-assignation.component';
import { PolicyBonusUpdaterComponent } from './policy-bonus-updater/pages/policy-bonus-updater.component';
import { PolicyCancellerComponent } from './policy-canceller/pages/policy-canceller.component';
import { PolicyCreateRootComponent } from './policy-create-root/pages/policy-create-root.component';
import { PolicyEditLayoutComponent } from './policy-edit/pages/policy-edit-layout.component';
import { PolicyHolderUpdaterComponent } from './policy-holder-updater/pages/policy-holder-updater.component';
import { PolicyLoaderComponent } from './policy-loader/pages/policy-loader.component';
import { PolicyRenewerComponent } from './policy-renewer/pages/policy-renewer.component';
import { PolicyRenumberingComponent } from './policy-renumbering/pages/policy-renumbering.component';
import { PolicyReplacerComponent } from './policy-replacer/pages/policy-replacer.component';
import { PolicyRiskUpdaterComponent } from './policy-risk-updater/pages/policy-risk-updater.component';
import { PolicySearchComponent } from './policy-search/pages/policy-search.component';
import { PolicyUpdaterComponent } from './policy-updater/pages/policy-updater.component';
import { RateChangerComponent } from './rate-changer/pages/rate-changer.component';
import { RootPolicyUpdaterComponent } from './root-policy-updater/pages/root-policy-updater.component';

export const PolicyRoutes = [
  {
    canActivate: [AuthGuard, ActiveFleetGuard],
    path: 'policies',
    title: $localize`:@@app.route.policies:Pólizas`,
    data: {
      icon: 'receipt_long',
      linkText: $localize`:@@app.route.policies:Pólizas`
    },
    children: [
      {
        path: 'create-root-policy',
        component: PolicyCreateRootComponent,
        title: $localize`:@@app.route.policies.create.root:Alta de póliza`,
        data: {
          linkText: $localize`:@@app.route.policies.create.root:Alta de póliza`
        }
      },
      {
        path: 'search',
        component: PolicySearchComponent,
        title: $localize`:@@app.route.policies.search:Consulta global`,
        data: {
          linkText: $localize`:@@app.route.policies.search:Consulta global`
        }
      },
      {
        path: 'edit/:id',
        component: PolicyEditLayoutComponent,
        title: $localize`:@@app.route.policies.edit:Modificar póliza`
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-loader',
        component: PolicyLoaderComponent,
        title: $localize`:@@app.route.policies.loader:Carga de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.loader:Carga de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-assignation',
        component: PolicyAssignationComponent,
        title: $localize`:@@app.route.policies.assignation:Asígnación de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.assignation:Asígnación de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-renumbering',
        component: PolicyRenumberingComponent,
        title: $localize`:@@app.route.policies.renumbering:Renumeración de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.renumbering:Renumeración de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-updater',
        component: PolicyUpdaterComponent,
        title: $localize`:@@app.route.policies.updater:Actualización de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.updater:Actualización de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-risk-updater',
        component: PolicyRiskUpdaterComponent,
        title: $localize`:@@app.route.policies.risk.updater:Actualización riesgos-pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.risk.updater:Actualización riesgos-pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'rate-changer',
        component: RateChangerComponent,
        title: $localize`:@@app.route.policies.rate.changer:Cambio de tarifa`,
        data: {
          linkText: $localize`:@@app.route.policies.rate.changer:Cambio de tarifa`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-bonus-updater',
        component: PolicyBonusUpdaterComponent,
        title: $localize`:@@app.route.policies.bonus.updater:Actualización primas`,
        data: {
          linkText: $localize`:@@app.route.policies.bonus.updater:Actualización primas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-holder-updater',
        component: PolicyHolderUpdaterComponent,
        title: $localize`:@@app.route.policies.holder.updater:Cambio de tomador`,
        data: {
          linkText: $localize`:@@app.route.policies.holder.updater:Cambio de tomador`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'booking-mode-changer-with-endorsement',
        component: BookingModeChangerWithEndorsementComponent,
        title: $localize`:@@app.route.policies.booking.mode.changer.with.endorsement:Cambio de modo de contratación (con suplemento)`,
        data: {
          linkText: $localize`:@@app.route.policies.booking.mode.changer.with.endorsement:Cambio de modo de contratación (con suplemento)`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'booking-mode-changer-without-endorsement',
        component: BookingModeChangerWithoutEndorsementComponent,
        title: $localize`:@@app.route.policies.booking.mode.changer.without.endorsement:Cambio de modo de contratación (sin suplemento)`,
        data: {
          linkText: $localize`:@@app.route.policies.booking.mode.changer.without.endorsement:Cambio de modo de contratación (sin suplemento)`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'root-policy-updater',
        component: RootPolicyUpdaterComponent,
        title: $localize`:@@app.route.policies.root.policy.updater:Cambio de póliza madre`,
        data: {
          linkText: $localize`:@@app.route.policies.root.policy.updater:Cambio de póliza madre`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-replacer',
        component: PolicyReplacerComponent,
        title: $localize`:@@app.route.policies.replacer:Traspaso de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.replacer:Traspaso de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-renewer',
        component: PolicyRenewerComponent,
        title: $localize`:@@app.route.policies.renewer:Renovación de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.renewer:Renovación de pólizas`
        }
      },
      {
        canActivate: [ActiveFleetGuard],
        path: 'policy-canceller',
        component: PolicyCancellerComponent,
        title: $localize`:@@app.route.policies.canceller:Baja de pólizas`,
        data: {
          linkText: $localize`:@@app.route.policies.canceller:Baja de pólizas`
        }
      }
    ]
  }
] as Routes;

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(PolicyRoutes)],
  exports: [RouterModule]
})
export class PoliciesRoutingModule {
}
