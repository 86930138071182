import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants';
import { IProductWarranty } from 'src/app/core/models/api/products';
import { IInsuranceCompany, IMediationCode } from 'src/app/core/models/ida/providers';
import { IInsuranceCompanyService } from './insurance-company.contract';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCompanyService implements IInsuranceCompanyService {
  constructor(private httpClient: HowdenHttpClientService) {
  }

  get(): Observable<Array<IInsuranceCompany>> {
    return this.httpClient.get<Array<IInsuranceCompany>>(APP_CONSTANTS.API_NET_FLOTAS, `insurance-companies`);
  }

  getMediationCodes(id: string): Observable<Array<IMediationCode>> {
    return this.httpClient.get<Array<IMediationCode>>(APP_CONSTANTS.API_NET_FLOTAS, `insurance-companies/${id}/mediation-codes`);
  }

  getProductWarranties(id: string, industryId: string, productId: string): Observable<Array<IProductWarranty>> {
    return this.httpClient.get<Array<IProductWarranty>>(APP_CONSTANTS.API_NET_FLOTAS, `insurance-companies/${id}/industries/${industryId}/products/${productId}/warranties`);
  }
}
