<form [formGroup]="model.form">
  <div class="howden-dialog-content text-center">

    <div mat-dialog-title class="title-text" i18n="@@app.booking.mode.create.title">Alta de modo de contratación</div>

    <mat-dialog-content>
      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.booking.mode.create.insuranceCompanyId.label">Aseguradora</mat-label>
          <input
            matInput
            [formControlName]="model.C_INSURANCE_COMPANY_ID"
            [matAutocomplete]="insuranceCompanySelect"
            [howdenControlError]="insuranceCompanyIdError"
          />
          <mat-autocomplete #insuranceCompanySelect="matAutocomplete" [displayWith]="getInsuranceCompanyName.bind(this)">
            @for (item of model.filteredInsuranceCompanies | async; track item.insuranceCompanyId) {
              <mat-option [value]="item.insuranceCompanyId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #insuranceCompanyIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.booking.mode.create.industryId.label">Ramo</mat-label>
          <input
            matInput
            [formControlName]="model.C_INDUSTRY_ID"
            [matAutocomplete]="industrySelect"
            [howdenControlError]="industryIdError"
          />
          <mat-autocomplete #industrySelect="matAutocomplete" [displayWith]="getIndustryName.bind(this)">
            @for (item of model.filteredIndustries | async; track item.industryId) {
              <mat-option [value]="item.industryId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #industryIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.booking.mode.create.productId.label">Producto</mat-label>
          <input
            matInput
            [formControlName]="model.C_PRODUCT_ID"
            [matAutocomplete]="productSelect"
            [howdenControlError]="productIdError"
          />
          <mat-autocomplete #productSelect="matAutocomplete" [displayWith]="getProductName.bind(this)">
            @for (item of model.filteredProducts | async; track item.productId) {
              <mat-option [value]="item.productId">
                {{ item.alias }}-{{ item.name }}
              </mat-option>
            }
          </mat-autocomplete>
          <mat-error #productIdError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex">
        <mat-form-field class="f-basis--12">
          <mat-label i18n="@@app.booking.mode.create.description.label">Denominación</mat-label>
          <input matInput [formControlName]="model.C_DESCRIPTION" [howdenControlError]="descriptionError" maxlength="100" />
          <mat-error #descriptionError></mat-error>
        </mat-form-field>
      </div>

      <div class="flex f-gap--10px">
        <h2 class="margin-0" i18n="@@app.booking.mode.create.warranties.title">Garantías producto</h2>
      </div>

      <div class="flex f-gap--10px">
        <howden-table
          class="small-font"
          paginable="local"
          sortable="none"
          [pageSize]="5"
          filterable="none"
          [cols]="model.warrantyColumns"
          [data]="model.warranties"
          >
        </howden-table>
      </div>

    </mat-dialog-content>

    <mat-dialog-actions>
      <button type="button" mat-raised-button (click)="onCancel()">
        <span i18n="@@app.core.actions.cancel">Cancelar</span>
      </button>

      <button type="button" mat-raised-button color="success" [disabled]="model.form.invalid" (click)="onAccept()">
        <span i18n="@@app.core.actions.accept">Aceptar</span>
      </button>
    </mat-dialog-actions>

  </div>
</form>
